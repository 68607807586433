<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="sign-up">
          <h1 class="header">
            Otwórz nowe konto
          </h1>
          <SignUpForm :redirect-on-success="redirectOnSuccess" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpForm from '@/vue/components/auth/sign-up-form.vue';

export default {
  name: 'SignUp',

  components: { SignUpForm },

  computed: {
    redirectOnSuccess() {
      return this.$route.query.redirectTo || 'my-account';
    },
  },
};
</script>
